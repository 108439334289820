<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="6" lg="4">
                        <mdb-input label="分類名稱" v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="6" class="pt-3 text-sm-right text-md-left">
                        <mdb-btn
                            color="default"
                            size="sm"
                            class="mt-3"
                            @click="
                                search.page = 1;
                                data.nowPage = 1;
                                searchdata();
                            "
                        >
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                        <mdb-btn
                            color="secondary"
                            size="sm"
                            class="mt-3"
                            v-show="search.name != ''"
                            @click="
                                search.name = '';
                                search.page = 1;
                                data.nowPage = 1;
                                searchdata();
                            "
                        >
                            取消搜尋</mdb-btn
                        >
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary" @click="add_class()">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增分類
                    </mdb-btn>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:10em">上層分類</th>
                            <th style="width:10em">排序</th>
                            <th style="width:50%">分類名稱</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sk) in product_classList" :key="`class_${sk}_${s.id}`">
                            <td>{{ sk + 1 }}</td>
                            <td>
                                <span v-show="!s.edit">{{
                                    top_classKeyList[s.id_parent] ? top_classKeyList[s.id_parent].name : '無'
                                }}</span>
                                <select class="form-control" v-model="s.id_parent" @change="s.edit = true" v-show="s.edit">
                                    <option value="0">無</option>
                                    <option v-for="tc in top_classList" :value="tc.id" :key="tc.id">{{ tc.name }}</option>
                                </select>
                            </td>
                            <td>
                                <span v-show="!s.edit">{{ s.sort }}</span>
                                <mdb-input type="number" class="my-0" v-model="s.sort" v-show="s.edit"></mdb-input>
                            </td>
                            <td @keypress.enter="save_class(s)">
                                <span v-show="!s.edit">{{ s.name }}</span>
                                <mdb-input
                                    type="text"
                                    class="my-0"
                                    placeholder="請輸入分類名稱"
                                    v-show="s.edit"
                                    v-model="s.name"
                                />
                            </td>
                            <td>
                                <mdb-btn class="text-nowrap" color="primary" size="sm" @click="s.edit = true" v-show="!s.edit">
                                    <i class="fa fa-edit mr-2"></i>
                                    編輯
                                </mdb-btn>
                                <mdb-btn color="default" size="sm" @click="save_class(s)" v-show="s.edit">
                                    <i class="fa fa-save mr-2"></i>
                                    儲存
                                </mdb-btn>
                                <mdb-btn class="text-nowrap" color="danger" size="sm" @click="delete_class(s, sk)">
                                    <i class="fa fa-trash mr-2"></i>
                                    刪除
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" v-show="product_classList.length == 0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page
                    :nowPage="data.nowPage"
                    :totalPage="data.totalPage"
                    @switchpage="
                        (pg) => {
                            search.page = pg;
                            getData();
                        }
                    "
                />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
    import { mdbCard, mdbCardHeader, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbBtn } from 'mdbvue';
    import page from '../../components/page';
    export default {
        props: { query: { default: {} } },
        components: {
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbRow,
            mdbCol,
            mdbInput,
            mdbBtn,
            page,
        },
        data() {
            return {
                search: {
                    name: '',
                    page: 1,
                },
                data: {
                    nowPage: 1,
                    totalPage: 0,
                },
                top_classList: [],
                product_classList: [],
                product_classEmpty: {},
            };
        },
        mounted() {
            console.clear();
            let vue = this;
            // 把篩選的資料放到變數裡面
            for (let i in vue.search) {
                vue.search[i] = vue.query[i] ? vue.query[i] : '';
            }
            vue.search.page = vue.search.page == '' ? 1 : vue.search.page;
            this.getData();
        },
        computed: {
            top_classKeyList() {
                let output = {};
                this.top_classList.forEach((item) => {
                    output[item.id] = item;
                });
                return output;
            },
        },
        methods: {
            // 搜尋資料
            searchdata() {
                let vue = this;
                let url = vue.$route.path + '?';
                for (let k in vue.search) {
                    url += `${k}=${vue.search[k]}&`;
                }
                vue.$router.push(url);
                vue.getData();
            },
            getData() {
                let vue = this;
                let query = [
                    // 取列表
                    {
                        name: 'product_class',
                        data: {},
                        sort: {
                            sort: 1,
                            id_parent: 0,
                        },
                        pg: vue.search.page == '' ? 1 : vue.search.page,
                    },
                    {
                        name: 'product_class',
                        data: {
                            id_parent: {
                                type: 0,
                                value: '0',
                            },
                        },
                        sort: {
                            name: 0,
                        },
                        return_name: 'top_class',
                    },
                    // 取數量
                    {
                        name: 'product_class',
                        data: {},
                        num: '1',
                    },
                    // 取空陣列
                    {
                        name: 'product_class',
                    },
                ];
                if (vue.search.name != '') {
                    query[0].data = query[1].data = {
                        name: {
                            type: 6,
                            value: `%${vue.search.name}%`,
                        },
                    };
                }
                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: `data/get_sql_data/?query=${encodeURI(JSON.stringify(query))}`,
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 'ok') {
                            // console.info(res.data);
                            // class
                            vue.product_classList = res.data.product_classList.map((item) => {
                                item.edit = false;
                                item.ajaxing = false;
                                item.active = item.active == '1';
                                return item;
                            });
                            vue.top_classList = res.data.top_classList;
                            res.data.product_classEmpty.edit = true;
                            res.data.product_classEmpty.ajaxing = false;
                            res.data.product_classEmpty.active = true;
                            res.data.product_classEmpty.id = '';
                            vue.product_classEmpty = res.data.product_classEmpty;
                            vue.data.totalPage = Math.ceil(res.data.product_classNumber / 10);
                            vue.data.nowPage = vue.search.page;
                        }
                    });
            },
            add_class() {
                this.product_classList.unshift(Object.assign({}, this.product_classEmpty));
            },
            save_class(s) {
                if (!s.ajaxing && s.edit) {
                    let vue = this,
                        msg = '';
                    switch (true) {
                        case !s.name || s.name == '':
                            msg = '請填寫 分類名稱';
                            break;
                    }

                    if (msg == '') {
                        s.ajaxing = true;
                        vue.$store
                            .dispatch('post_form', {
                                payload: {
                                    url: 'product/product_class_save',
                                    data: { data: JSON.stringify(s) },
                                },
                            })
                            .then((res) => {
                                if (res.data.status == 'ok') {
                                    s.id = res.data.id_product_class;
                                    s.edit = false;
                                    s.ajaxing = false;
                                    // 加入上層選項
                                    if (s.id_parent == 0) {
                                        vue.top_classList.push(s);
                                    }
                                }
                            });
                    } else {
                        vue.$swal.fire({
                            icon: 'error',
                            title: msg,
                            timer: 1500,
                            showConfirmButton: false,
                            toast: true,
                        });
                    }
                }
            },
            delete_class(s, sk) {
                let vue = this;
                if (!s.ajaxing) {
                    // 還沒儲存過的可以直接刪除
                    if (s.id == '') {
                        vue.product_classList.splice(sk, 1);
                    } else {
                        vue.$swal.fire({
                            icon: 'question',
                            title: '確定要刪除嗎?',
                            showConfirmButton: true,
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            preConfirm() {
                                s.ajaxing = true;
                                return vue.$store
                                    .dispatch('post_form', {
                                        payload: {
                                            url: 'product/product_class_delete',
                                            data: { class_id: s.id },
                                        },
                                    })
                                    .then((res) => {
                                        if (res.data.status == 'success') {
                                            vue.product_classList.splice(sk, 1);
                                        }
                                        vue.$swal.fire({
                                            icon: res.data.status,
                                            title: res.data.msg,
                                            showConfirmButton: true,
                                            timer: 1500,
                                        });
                                    });
                            },
                        });
                    }
                }
            },
        },
    };
</script>
